import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { ResponseModel } from '../model/response-model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotifyModel } from '../model/notify-model';
import { NotificationService } from './notification.service';
import { FirebaseData, ObservableFirebaseList } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  public static typeResponses = 'responses';  // Предложения на объявления

  responses = 'responses';

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) { }

  // Отправить предложение на объявление
  addResponse(idAd: string, userAd: string, response: ResponseModel): Promise<any> {
    response.user = this.authService.getLogin();
    response.dateCreate = new Date().toISOString();
    return this.db.object(`${this.responses}/${idAd}/${this.authService.getLogin()}`)
      .set(response)
      .then(() => {
        const notify = new NotifyModel();
        notify.subject = 'Новое предложение';
        notify.text = response.response;
        notify.url = '/detail/' + idAd;
        notify.user = this.authService.getLogin();

        return this.notificationService.updateNotify(`Ad_${idAd}`, userAd, notify);
      });
  }

  // получить все предложения по объявлению
  getResponse(key: string): AngularFireList<ResponseModel> {
    return this.db.list(ResponseService.typeResponses + '/' + key + '');
  }

  // мое предложение
  getMyResponse(key: string): AngularFireObject<ResponseModel> {
    return this.db.object(ResponseService.typeResponses + '/' + key + '/' + this.authService.getLogin());
  }

  list(userID: string, adID: string): ObservableFirebaseList {
    const isMyAd = (userID === this.authService.getLogin());

    if (isMyAd) {
      return this.getResponseObserver(adID);
    } else {
      return this.getMyResponseObserver(adID);
    }
  }

  // получить все предложения по объявлению
  getResponseObserver(id: string): Observable<ResponseModel[]> {
    return this.db.list(`${this.responses}/${id}`).valueChanges()
      .pipe(
        map(items =>
          items.map(item => {
            return item as ResponseModel;
          })
        ),
        tap(responseList => {
          responseList.forEach(responseDetail => {
            this.notificationService.setMarkRead(`Ad_${id}_${responseDetail.user}`);
          });
        })

      );
  }

  // мое предложение
  getMyResponseObserver(id: string): Observable<ResponseModel[]> {
    return this.db
      .object(`${this.responses}/${id}/${this.authService.getLogin()}`)
      .valueChanges()
      .pipe(
        map((item: ResponseModel) => {
          if (!item) {
            item = new ResponseModel();
          }
          item.isMyResponse = true;

          return [item];
        })
      );
  }

  // удалить предложение
  removeResponse(note: ResponseModel) {
    this.db.list(ResponseService.typeResponses).remove(note.key);
  }
}
