import { UploadFileService } from './../../services/upload-file.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subject } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import 'firebase/storage';
import * as firebase from 'firebase/app';

@Component({
  selector: 'inputfile',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {

  isLoading: boolean;
  file: string;

  @Input() value: string;
  @Output() valueChange = new EventEmitter();

  constructor(
    private uploadFileService: UploadFileService
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.valueChange.subscribe(urlUploadFile => {
      this.file = urlUploadFile;
      this.isLoading = false;
    });
  }

  addFilesFake() {
    document.getElementById('file_uploading').click();
  }

  addFiles(event) {
    const target = event.target || event.srcElement;
    this.isLoading = true;
    this.uploadFileService.saveFileToStorage(target.files[0]).subscribe(fileUrl => {
      this.valueChange.emit(fileUrl);
    });
  }

}
