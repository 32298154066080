import { Pipe, PipeTransform } from '@angular/core';

interface filterValues {
  type: string,
  value: any
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value, args: filterValues) {
    return value ? value.filter(item => {
      if (args.type == 'gender') return  item.gender == args.value || item.gender == 'all';
      if (args.type == 'brand') return item.brand.id == args.value
    }) : []
  }

}
