import { Injectable } from '@angular/core';
import * as firebase from "firebase";
import {CorequeryService} from "../services/corequery.service";
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class PayService {


  constructor(
      private coreQueryService: CorequeryService,
      private authService: AuthService
  ) { }

  generatePay(data) {
    const getPayFindexiq = firebase.functions().httpsCallable('getPayFindexiq');

    return getPayFindexiq(data)
  }

  addTransaction(transaction) {
    return this.coreQueryService.addBlock(`transactionsShop/${this.authService.getLogin()}/transactions`, transaction)
  }


  sendEmail(data) {
    const sendEmail = firebase.functions().httpsCallable('sendEmail');

    return sendEmail(data)
  }

}
