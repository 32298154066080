import { Pipe, PipeTransform } from '@angular/core';
import { FirebaseData } from '../services/core.service';

@Pipe({
  name: 'sortDialog'
})
export class SortDialogPipe implements PipeTransform {

  transform(value: Array<FirebaseData>, args?: any): any {
    if (value) {
      return value.sort(this.compare);
    }
  }

  compare(a: FirebaseData, b: FirebaseData) {
    console.log(a, b);

    if (a.dateCreate < b.dateCreate) {
      return 1;
    } else if (a.dateCreate > b.dateCreate) {
      return -1;
    }
    return 0;
  }

}
