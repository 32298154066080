import {Component, NgZone, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import {BehaviorSubject, Observer, ReplaySubject} from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {BasketService} from "../../servicesProject/basket.service";
import {CategoryService} from "../../services/category.service";
import {FavoritesService} from "../../servicesProject/favorites.service";
import {TovarsService} from "../../servicesProject/tovars.service";
import {debounceTime, first, map} from "rxjs/operators";
import {CorequeryService} from "../../services/corequery.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user$: Observer<firebase.User>;
  basketLength = 0;
  favoriteLength = 0;
  search;
  listTovars;
  categoryList$;
  gender$: BehaviorSubject<string> = new BehaviorSubject('g');

  constructor(
      public authService: AuthService,
      private router: Router,
      public basketService: BasketService,
      private categoryService: CategoryService,
      public favoritesService: FavoritesService,
      private tovarsService: TovarsService,
      public ngZone: NgZone,
      private coreQueryService: CorequeryService
  ) {}

  ngOnInit() {
    this.gender$.subscribe(gender => {
      this.categoryList$ = this.coreQueryService.listBlock('categories')
          .pipe(
              map(data => {
                return data.sort((a, b) => Number(a.idCat) > Number(b.idCat) ? 1 : -1);
              }),
              map(data => {
                return data.map(item => {
                  this.coreQueryService.listBlock(`categories/${item.key}/underCategory`)
                      .pipe(
                          first()
                      )
                      .toPromise()
                      .then(underCat => {
                        item.underCategory = underCat.filter(i => i.gender == gender || i.gender == 'all');
                      });
                  return item
                })
              })
          );
    });


    this.user$ = this.authService.user;
    this.basketService.basket$.subscribe(data => {
      this.basketLength = Object.values(data).length
    });

    this.favoritesService.favorites$.subscribe(data => {
      this.favoriteLength = Object.values(data).length
    })
  }

  goToFavorites() {
      if (this.favoriteLength) this.router.navigate(['/tabs/favorites'])
  }

  redirectToCategory(gender: string) {
    this.categoryService.gender$.next(gender);

    this.router.navigateByUrl('/tabs/categories');
  }

  clickPoint(g) {
    this.gender$.next(g)
  }

  close() {
    const mainMenuBackdrop = <HTMLElement>document.querySelector('.main-menu-backdrop');
    const mainMenuButton = document.querySelector('.header_main-line_open-menu-btn');
    const mainMenuWrapper = document.querySelector('.main-menu_wrapper');
    const mainMenu = document.querySelector('.main-menu');

    mainMenuWrapper.classList.remove('is-open');
    mainMenuButton.classList.remove('is-open');
    mainMenu.classList.remove('is-open');

    mainMenuBackdrop.style.display = 'none';
  }

  toggleBurger(event) {
    const thisEl = event.srcElement;
    const mainMenuWrapper = document.querySelector('.main-menu_wrapper');
    const mainMenuBackdrop = <HTMLElement>document.querySelector('.main-menu-backdrop');
    const mainMenu = document.querySelector('.main-menu');
    if (!thisEl.classList.contains('is-open')) {
      mainMenuWrapper.classList.add('is-open');
      mainMenu.classList.add('is-open');
      thisEl.classList.add('is-open');
      mainMenuBackdrop.style.display = 'block';
    } else {
      mainMenuWrapper.classList.remove('is-open');
      mainMenu.classList.remove('is-open');
      thisEl.classList.remove('is-open');
      mainMenuBackdrop.style.display = 'none';
    }
  }

  searchTovar(event) {
    console.log(event)
    if(event) {
      this.ngZone.run(() => {
        this.search = event;
        this.tovarsService.listTovarsSearch()
            .pipe(
                debounceTime(500),
                first()
            )
            .toPromise().then(data => {
          console.log(data)
          this.listTovars = data.filter(item => {
            return item.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1 || item.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
          })
        })
      })
    }

  }
}
