import { Component, OnInit } from '@angular/core';
import { FavoritesService } from 'src/app/servicesProject/favorites.service';
import {BasketService} from "../../servicesProject/basket.service";

@Component({
  selector: 'app-product',
  inputs: ['item'],
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  item;

  constructor(
    public basketService: BasketService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit() {
  }

}
