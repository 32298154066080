import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FIREBASE_CONFIG } from '../firebase.credentials';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { IonicStorageModule } from '@ionic/storage';

import { Camera } from '@ionic-native/camera/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HeaderComponentModule } from './components/header/header.module';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import {FirebaseDynamicLinks} from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import {AppLauncher} from '@ionic-native/app-launcher/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeRu, 'en');

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: ''
        }),
        AppRoutingModule,
        HeaderComponentModule,
        AngularFireModule.initializeApp(FIREBASE_CONFIG),
        IonicStorageModule.forRoot({
            name: '__market',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        AngularFireDatabaseModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        FirebaseDynamicLinks,
        FirebaseX,
        ScreenOrientation,
        InAppBrowser,
        AppMinimize,
        AngularFirestore,
        AppLauncher,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        Camera,
        PhotoViewer
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
