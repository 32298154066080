import { IonicModule } from '@ionic/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormUploadComponent } from './../../components/form-upload/form-upload.component'
import { FormsModule } from '@angular/forms'
import { ResponsesListComponent } from './../../components/responses-list/responses-list.component'
import { UserDetailComponent } from './../../components/user-detail/user-detail.component'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SortAdPipe } from 'src/app/pipe/sort-ad.pipe'
import { DateDialogPipe } from 'src/app/pipe/date-dialog.pipe'
import { FormUpload2Component } from 'src/app/components/form-upload2/form-upload2.component'
import { InputFileComponent } from 'src/app/components/input-file/input-file.component'
import { SortDialogPipe } from 'src/app/pipe/sort-dialog.pipe'
import { WithdrawComponent } from '../../components/withdraw/withdraw.component'
import { ProductComponent } from 'src/app/components/product/product.component'
import { HtmlSanitizerPipe } from 'src/app/pipe/sanitaze-html.pipe'
import {FilterPipe} from "../../pipe/filter.pipe";

@NgModule({
	declarations: [
		UserDetailComponent,
		FormUploadComponent,
		FormUpload2Component,
		ResponsesListComponent,
		SortAdPipe,
		DateDialogPipe,
		InputFileComponent,
		SortDialogPipe,
		WithdrawComponent,
		ProductComponent,
		HtmlSanitizerPipe,
		FilterPipe
	],
	imports: [CommonModule, IonicModule.forRoot(), FormsModule, RouterModule, TranslateModule.forChild()],
	exports: [
		UserDetailComponent,
		FormUploadComponent,
		FormUpload2Component,
		SortAdPipe,
		ResponsesListComponent,
		TranslateModule,
		DateDialogPipe,
		InputFileComponent,
		SortDialogPipe,
		WithdrawComponent,
		ProductComponent,
		HtmlSanitizerPipe,
		FilterPipe
	],
	entryComponents: [WithdrawComponent],
})
export class CoreModule {
	constructor() {}
}
