import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CoreModule } from '../../modules/core/core.module';
import { HeaderComponent } from './header.component';


@NgModule({
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    RouterModule,
  ],
  declarations: [HeaderComponent]
})
export class HeaderComponentModule {}
