import {Injectable, NgZone} from '@angular/core';
import {CoreService, FirebaseData} from "../services/core.service";
import {ReplaySubject} from "rxjs";
import {Storage} from "@ionic/storage";
import {AngularFireDatabase} from "@angular/fire/database";
import {AuthService} from "../services/auth.service";
import {first, map} from "rxjs/operators";
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  basket: FirebaseData = {};
  basket$: ReplaySubject<FirebaseData> = new ReplaySubject(1);

  constructor(
      private core: CoreService,
      private storage: Storage,
      private db: AngularFireDatabase,
      private authService: AuthService,
      private ngZone: NgZone,
  ) {
    this.authService.auth().then(uid => {
      if (uid) {
        this.db.list(`basket/${uid}`)
            .valueChanges()
            .pipe(
                first()
            )
            .toPromise()
            .then(data => {
              console.log(data)
              console.log('The parameters of the basket have been read', data);
              if (data.length) {
                data.forEach((item: any) => {
                  this.basket[item.id] = item;
                })

              }
              console.log(this.basket)
              this.refreshBasket();
            })
      }
    });
    this.refreshBasket();
  }

  add(item: FirebaseData, count: number) {
    if (this.basket[item.id]) {
      const oldCount = parseFloat(this.basket[item.key].count);
      this.basket[item.id] = item;
      this.basket[item.id].count = oldCount + count;
      this.db.object(`basket/${this.authService.getLogin()}/${item.id}`).update(item);
    } else {
      this.basket[item.id] = item;
      this.basket[item.id].count = count;
      this.db.object(`basket/${this.authService.getLogin()}/${item.id}`).set(item);
    }
    this.core.presentToast('The product has been added to the cart.');
    this.refreshBasket();
  }

  refreshBasket() {
    this.ngZone.run(() => {
      this.basket$.next(this.basket);
    });
  }

  list() {
    return this.basket$.asObservable()
        .pipe(
            map(items => {
              return Object.keys(items).map(key =>
                  items[key]
              );
            })
        );
  }

  changeCount(id: string, count: number) {
    this.basket[id].count = count;
    this.db.object(`basket/${this.authService.getLogin()}/${id}`).update(this.basket[id]);
    this.refreshBasket();
  }

  deleteKey(hashmap: FirebaseData, keyRemove: string) {
    return Object.keys(hashmap).reduce((newObj, key) => {
      if (key !== keyRemove) {
        newObj[key] = hashmap[key];
      }

      return newObj;
    }, {});
  }


  delete(id: string) {
    console.log('удаляем', id);
    this.basket = this.deleteKey(this.basket, id);
    this.db.object(`basket/${this.authService.getLogin()}/${id}`).remove();
    this.refreshBasket();
  }

  sendOrder(uid?: string, user?): Promise<any> {
    uid = uid ? uid : this.authService.getLogin();
    const shopId = Object.values(this.basket)[0].shop;
    const sendOrder = firebase.functions().httpsCallable('sendOrder');
    return sendOrder({
      zakaz: {
        magazine: shopId,
        user: uid,
        userName: user.name
      },
      zakaztovar: this.basket
    });
  }


  clear() {
    this.basket = {};
    this.refreshBasket();
  }
}
