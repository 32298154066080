import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './tabs/tabs.module#TabsPageModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'peopledetail/:id',
    children:
      [
        {
          path: '',
          loadChildren: './Tab_People/people-detail/people-detail.module#PeopleDetailPageModule'
        }
      ],
    canActivate: [AuthGuard]
  },
  {
    path: 'chat/:id',
    children:
      [
        {
          path: '',
          loadChildren: './OtherPages/chat/chat.module#ChatPageModule'
        }
      ],
    canActivate: [AuthGuard]
  },
  {
    path: 'detail/:id',
    children:
      [
        {
          path: '',
          loadChildren: './OtherPages/detail/detail.module#DetailPageModule'
        }
      ],
    // canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: './Tab_Setting/auth/auth.module#AuthPageModule'
  },
  {
    path: 'agreement',
    loadChildren: './Tab_Setting/agreement/agreement.module#AgreementPageModule'
  },
  {
    path: 'fullsreen-image/:url',
    loadChildren: './OtherPages/fullsreen-image/fullsreen-image.module#FullsreenImagePageModule'
  },
  {
    path: 'tovar/:id/:shopid',
    loadChildren: './Tab_Categories/tovar/tovar.module#TovarPageModule',
    // canActivate: [AuthGuard]

  },
  { path: 'main', loadChildren: './main/main.module#MainPageModule' },
  { path: 'catalog', loadChildren: './catalog/catalog.module#CatalogPageModule' },
  { path: 'catalog-level1', loadChildren: './catalog-level1/catalog-level1.module#CatalogLevel1PageModule' },
  { path: 'products-catalog', loadChildren: './products-catalog/products-catalog.module#ProductsCatalogPageModule' },
  { path: 'product-detail', loadChildren: './product-detail/product-detail.module#ProductDetailPageModule' },
  { path: 'search', loadChildren: './search/search.module#SearchPageModule' },
  { path: 'filter', loadChildren: './filter/filter.module#FilterPageModule' },
  { path: 'connect', loadChildren: './components/connect/connect.module#ConnectPageModule'},
  { path: 'faq', loadChildren: './components/faq/faq.module#FaqPageModule'},
  { path: 'safe-deal', loadChildren: './components/safe-deal/safe-deal.module#SafeDealPageModule'},
  { path: 'journal', loadChildren: './components/journal/journal.module#JournalPageModule'},
  { path: 'error', loadChildren: './components/errors/errors.module#ErrorsPageModule'},
  { path: 'policy', loadChildren: './Tab_Setting/policy/policy.module#PolicyPageModule' },
  { path: 'gender-products/:gender', loadChildren: './Tab_AllAd/gender-products/gender-products.module#GenderProductsPageModule' },
  { path: 'favorites', loadChildren: './Tab_Basket/favorites/favorites.module#FavoritesPageModule' },
  {
    path: 'all-brands',
    loadChildren: './Tab_AllAd/all-brands/all-brands.module#AllBrandsPageModule'
  },
  {
    path: 'detail-brands/:id',
    loadChildren: './Tab_AllAd/detail-brands/detail-brands.module#DetailBrandsPageModule'
  },
  {
    path: 'success-pay',
    loadChildren: './Tab_Setting/success-pay/success-pay.module#SuccessPayPageModule'
  },
  {
    path: 'balance',
    loadChildren: './Tab_Setting/balance/balance.module#BalancePageModule'
  },
  {
    path: 'dialogs',
    loadChildren: './Tab_Dialogs/dialogs/dialogs.module#DialogsPageModule',
    canActivate: [AuthGuard]
  },
  { path: '500', loadChildren: './components/errors/errors.module#ErrorsPageModule'},
  { path: '404', loadChildren: './components/error404/error404.module#Error404PageModule'},
  { path: '**', redirectTo: '/404'}

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
