import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { PeopleModel } from '../model/people-model';
import { AuthService } from './auth.service';
import { Observable, pipe } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { CoreService, FirebaseData } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public static typeMasters = 'masters';  // Мастера

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private storage: Storage
  ) { }

  // Добавить мастера
  addPeople(note: FirebaseData): Promise<any> {
    note = Object.keys(note).reduce((acc, key) => {
      if (note[key]) {
        acc[key] = note[key];
      }
      return acc;
    }, {});

    note.dateCreate = new Date().toISOString();
    note.user = this.authService.getLogin();

    console.log(note)

    return this.db.object('/shop/' + note.user).update(note);
  }

  // Список мастеров из кэша
  getPeopleListCache() {
    return this.storage.get('cache_master');
  }

  // Список мастеров
  getPeopleListServer(): Observable<any[]> {
    return this.db.list(MasterService.typeMasters).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.val() as PeopleModel;
        data.key = a.payload.key;

        CoreService.trimField(data, 'fio', 60);
        CoreService.trimField(data, 'title', 80);

        return data;
      }))
    );
  }

  updateShop(data, id?) {
    return this.db.object(`shop/${id ? id : this.authService.getLogin()}`).update(data)
  }

  // информация о мастере
  getPeopleDetail(id: string): AngularFireObject<PeopleModel> {
    return this.db.object(MasterService.typeMasters + '/' + id);
  }

  // информация о мастере
  detail(id?: string): Observable<PeopleModel> {
    id = id ? id : this.authService.getLogin();
    return this.db.object('/shop/' + id)
      .valueChanges()
      .pipe(
        map(items => (items ? items : {}) as PeopleModel)
      );
  }

  isExistProfileMaster() {
    return new Promise((resolve, reject) => {
      this.detail()
        .pipe(
          first()
        )
        .subscribe(peopleDetail => {
          resolve(!!peopleDetail.dateCreate);
        });
    });
  }
}
