import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateDialog'
})
export class DateDialogPipe extends DatePipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    if ((new Date().getTime() - new Date(value).getTime()) / 1000 / 60 / 60 / 24 < 1) {
      return super.transform(value, 'HH:mm');
    }
    return super.transform(value, 'dd.MM.yy');
  }

}
