import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    path: ActivatedRouteSnapshot[]; route: ActivatedRouteSnapshot;

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.authService.state.asObservable()
            .pipe(
                tap(isAuth => {
                    if (!isAuth) {
                        this.router.navigate(['/auth']);
                    }
                })
            );
    }
}

