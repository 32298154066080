import { Component, OnInit, Input } from '@angular/core';
import { PeopleModel } from '../../model/people-model';
import { MasterService } from 'src/app/services/master.service';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/app/servicesProject/user.service';
import { FirebaseData, ObservableFirebaseDetail } from 'src/app/services/core.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'rtp-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  @Input() idUser: string;
  @Input() idUserElseEmpty: string;
  @Input() field: string;

  detail$: ObservableFirebaseDetail;

  constructor(
    private masterService: MasterService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    if (!this.field) {
      this.field = 'name';
    }

    this.detail$ = this.userService.detail(this.idUser)
      .pipe(
        switchMap(item =>
          item.user ? of(item) : this.userService.detail(this.idUserElseEmpty)
        )
      );
  }

}
